import { render, staticRenderFns } from "./InputSelect.vue?vue&type=template&id=246e9877&scoped=true"
import script from "./InputSelect.vue?vue&type=script&setup=true&lang=ts&generic=T%20extends%20%7B%20item%3A%20string%2C%20value%3A%20string%20%7D"
export * from "./InputSelect.vue?vue&type=script&setup=true&lang=ts&generic=T%20extends%20%7B%20item%3A%20string%2C%20value%3A%20string%20%7D"
import style0 from "./InputSelect.vue?vue&type=style&index=0&id=246e9877&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "246e9877",
  null
  
)

export default component.exports